var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{staticClass:"overflow-hidden mb-2",attrs:{"title":"HSM","subtitle":_vm.lang.helpTooltips.surveys.hsm[_vm.languageSelected]}},[_c('div',{staticClass:"vx-row"},[(_vm.namespace != undefined)?_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full mt-1",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .nameSpace.label[_vm.languageSelected],"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .nameSpace.placeholder[_vm.languageSelected]},model:{value:(_vm.nameSpaceR),callback:function ($$v) {_vm.nameSpaceR=$$v},expression:"nameSpaceR"}})],1):_vm._e(),_vm._v(" "),_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full mt-1",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .elementName.label[_vm.languageSelected],"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .elementName.placeholder[_vm.languageSelected]},model:{value:(_vm.elementNameR),callback:function ($$v) {_vm.elementNameR=$$v},expression:"elementNameR"}})],1),_vm._v(" "),_c('vs-col',{attrs:{"vs-lg":'6',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full mt-1",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .languageCode.placeholder[_vm.languageSelected],"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .languageCode.placeholder[_vm.languageSelected]},model:{value:(_vm.languageCodeR),callback:function ($$v) {_vm.languageCodeR=$$v},expression:"languageCodeR"}})],1)],1),_vm._v(" "),(_vm.showVariables)?_c('div',{staticClass:"vx-row mt-2"},[_c('vs-list',[_c('vs-list-header',{attrs:{"icon-pack":"feather","icon":"icon-code","title":_vm.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
            .variables[_vm.languageSelected]}}),_vm._v(" "),_c('draggable',{staticClass:".variables",staticStyle:{"overflow-y":"auto","max-height":"250px"},model:{value:(_vm.variablesR),callback:function ($$v) {_vm.variablesR=$$v},expression:"variablesR"}},_vm._l((_vm.variablesR),function(text,index){return _c('vs-list-item',{key:index,staticStyle:{"cursor":"move"},attrs:{"icon-pack":"feather","icon":"icon-chevron-right","rounded":"","subtitle":text}},[_c('vs-button',{attrs:{"radius":"","type":"flat","icon":"clear"},on:{"click":function($event){_vm.removeVariable(index)}}})],1)}),1)],1)],1):_vm._e(),_vm._v(" "),(_vm.showVariables)?_c('vs-input',{staticClass:"inputx w-full mt-2 mb-base",attrs:{"placeholder":("var " + (_vm.variablesR.length + 1))},on:{"keypress":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addVariable($event)}},model:{value:(_vm.newVariable),callback:function ($$v) {_vm.newVariable=$$v},expression:"newVariable"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }