<template>
  <vx-card
    class="overflow-hidden mb-2"
    title="HSM"
    :subtitle="lang.helpTooltips.surveys.hsm[languageSelected]"
  >
    <div class="vx-row">
      <vs-col
        v-if="namespace != undefined"
        :vs-lg="'6'"
        :vs-xs="'12'"
        :vs-sm="'12'"
      >
        <vs-input
          class="inputx w-full mt-1"
          :label="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .nameSpace.label[languageSelected]
          "
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .nameSpace.placeholder[languageSelected]
          "
          v-model="nameSpaceR"
        />
      </vs-col>
      <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-input
          class="inputx w-full mt-1"
          :label="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .elementName.label[languageSelected]
          "
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .elementName.placeholder[languageSelected]
          "
          v-model="elementNameR"
        />
      </vs-col>
      <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-input
          class="inputx w-full mt-1"
          :label="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .languageCode.placeholder[languageSelected]
          "
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .languageCode.placeholder[languageSelected]
          "
          v-model="languageCodeR"
        />
      </vs-col>
    </div>
    <div class="vx-row mt-2" v-if="showVariables">
      <vs-list>
        <vs-list-header
          icon-pack="feather"
          icon="icon-code"
          :title="
            lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .variables[languageSelected]
          "
        ></vs-list-header>
        <draggable
          v-model="variablesR"
          class=".variables"
          style="overflow-y: auto; max-height: 250px;"
        >
          <vs-list-item
            v-for="(text, index) in variablesR"
            :key="index"
            icon-pack="feather"
            icon="icon-chevron-right"
            rounded
            :subtitle="text"
            style="cursor: move;"
          >
            <vs-button
              radius
              type="flat"
              icon="clear"
              @click="removeVariable(index)"
            ></vs-button>
          </vs-list-item>
        </draggable>
      </vs-list>
    </div>
    <vs-input
      v-if="showVariables"
      :placeholder="`var ${variablesR.length + 1}`"
      class="inputx w-full mt-2 mb-base"
      v-model="newVariable"
      @keypress.enter="addVariable"
    />
  </vx-card>
</template>

<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'Hsm',
  props: {
    survey: Object,
    variables: {
      type: Array,
      default: function() {
        return [
          '*KONECTA*',
          `Estás listo para responder la encuesta *${this.survey.service.name}*? Responde *SI* o *NO*`
        ]
      }
    },
    showVariables: {
      type: Boolean,
      default: true
    },
    elementName: {
      type: String
      // default: 'chatclub_southcone_welcome_v1' // 'chatclub_open_session_v2'
    },
    languageCode: {
      type: String,
      default: 'es'
    },
    namespace: {
      type: String
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      languageCodeR: '',
      newVariable: null,
      elementNameR: '',
      variablesR: [],
      nameSpaceR: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    removeVariable(index) {
      this.variablesR.splice(index, 1)
    },
    addVariable() {
      if (this.newVariable && this.newVariable.length > 0) {
        this.variablesR.push(`${this.newVariable}`)
        this.newVariable = ''
        var container = this.$el.querySelector('.variables')
        if (container) {
          container.scrollTop = container.scrollHeight - 100
        }
      }
    }
  },
  watch: {
    variablesR(newVal) {
      this.$emit('update:variables', newVal)
    },
    languageCodeR(newVal) {
      this.$emit('update:languageCode', newVal)
    },
    elementNameR(newVal) {
      this.$emit('update:elementName', newVal)
    },
    nameSpaceR(newVal) {
      this.$emit('update:namespace', newVal)
    }
  },
  mounted() {
    this.nameSpaceR = `${this.namespace}`
    this.elementNameR = `${this.elementName}`
    this.languageCodeR = `${this.languageCode}`
    if (this.variables && this.variables.length) {
      this.variablesR = Object.assign(this.variables)
    } else if (this.survey) {
      this.variablesR = [
        '*KONECTA*',
        `Estás listo para responder la encuesta *${this.survey.service.name}*? Responde *SI* o *NO*`
      ]
    }
  }
}
</script>
